import { useEffect, useState } from 'react';

export function useFetchJson<T>(path: string, initState: T): T {
	const [state, setState] = useState<T>(initState);

	useEffect(() => {
		fetch(path)
			.then((r) => r.json())
			.then(setState);
	}, [path]);

	return state;
}
