import React, { CSSProperties } from 'react';
import { ReactComponent as Triangle } from '../assets/triangle.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: absolute;
	transform: translate(-50%, -100%);
	display: flex;
	flex-flow: column;
	align-items: center;
`;

const StyledTriangle = styled(Triangle)`
	margin-top: 2px;
	width: 14px;
	height: auto;
`;

interface LandmarkMarker {
	className?: string;
	style?: CSSProperties;
	position: [number, number];
	scale?: number;
}


export const LandmarkMarker: React.FC<LandmarkMarker> = ({position: [left, top],scale=1, className, style, children}) => {
	return <Wrapper className={className} style={{...style, left, top}}>
		{children}
		<StyledTriangle scale={scale} />
	</Wrapper>
};
