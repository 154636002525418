import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import SwiperCore from 'swiper';
import { useFetchJson } from '../hooks/useFetchJson';

interface AppContextProps {
	images: Image[];
	markers: Marker[];
	setSwiper: (swiper: SwiperCore) => void;
	handleIndexChange: (activeIndex: number) => void;
	currentDistance: number;
	selectDistance: (distance: number) => void;

	toggleMap: () => void;
	showMap: boolean;

	sidebarVisible: boolean;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider: FC = ({ children }) => {
	const [swiper, setSwiper] = useState<SwiperCore>();
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const images = useFetchJson<Image[]>('/assets/baltikett-images.json', []);
	const markers = useFetchJson<Marker[]>('/assets/baltikett-markers.json', []);
	const [showMap, setShowMap] = useState(false);
	const sidebarVisible = useMemo(() => showMap, [showMap]);
	const initialDistance = useMemo(() => getDistanceFromHash(), []);

	const currentDistance = useMemo<number>(() => {
		return images?.[activeIndex]?.distance || initialDistance;
	}, [activeIndex, images, initialDistance]);

	function toggleMap() {
		setShowMap((prevState) => !prevState);
	}

	function getDistanceFromHash() {
		return parseInt(window.location.hash.slice(1));
	}

	const selectDistance = useCallback(
		(distance: number) => {
			const closestData = images.reduce(
				(previousValue, currentImage, index) => {
					const dist = Math.abs(currentImage.distance - distance);
					return previousValue.dist < dist ? previousValue : { index, dist };
				},
				{ index: 0, dist: Infinity }
			);

			swiper?.slideTo(closestData.index);
		},
		[images, swiper]
	);

	useEffect(() => {
		selectDistance(initialDistance);
	}, [initialDistance, selectDistance]);

	useEffect(() => {
		window.history.replaceState(null, '', `#${currentDistance}`);
	}, [currentDistance]);

	return (
		<AppContext.Provider
			value={{
				images,
				setSwiper,
				handleIndexChange: setActiveIndex,
				currentDistance,
				markers,
				selectDistance,
				showMap,
				toggleMap,
				sidebarVisible,
			}}
			children={children}
		/>
	);
};

export const useAppContext = () => useContext(AppContext);
