import React from 'react';
import './i18n';
import { useAppContext } from './contexts/AppContext';

import SwiperCore, { Keyboard, Mousewheel, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import 'swiper/swiper-bundle.css';
import { GlobalStyles } from './styles/GlobalStyles';
import {
	Button,
	Overlay,
	MapIcon,
	CloseIcon,
	GlobalButtonContainer,
	FullscreenToggle,
} from '@erm-tasku/components';
import { RouteMap } from './components/RouteMap';
import { useTranslation } from 'react-i18next';
import { screenSize } from '@erm-tasku/styles';

SwiperCore.use([Virtual, Mousewheel, Keyboard]);

const StyledSwiper = styled(Swiper)`
	width: 100vw;
	height: 100vh;

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-slide {
		height: 100%;
	}
`;

const DistanceBox = styled.div`
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
`;

const Image = styled.img`
	max-width: 90vw;
	max-height: 90vh;
`;

const SlideWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledOverlay = styled(Overlay)`
	align-items: flex-end;
	z-index: 100;
`;

const MapBox = styled.div`
	background: var(--dark-blue);
	height: 90vh;
	width: 60vw;
	display: flex;
	overflow: hidden;
	
	${screenSize.sm} {
		width: calc(100vw - 56px); //56 Button width
	}
`;

function App() {
	const { images, setSwiper, handleIndexChange, currentDistance, sidebarVisible, showMap, toggleMap } = useAppContext();
	const { t } = useTranslation();

	if (!images.length) return <div>loading</div>;

	return (
		<>
			<GlobalStyles />
			<StyledSwiper
				onSwiper={setSwiper}
				onActiveIndexChange={(swiper) => handleIndexChange(swiper.activeIndex)}
				virtual={{ addSlidesAfter: 4, addSlidesBefore: 4 }}
				freeMode={true}
				preloadImages={false}
				freeModeSticky={true}
				centeredSlidesBounds={true}
				centeredSlides={true}
				mousewheel={true}
				keyboard={true}
			>
				{images.map((image, index) => {
					return (
						<SwiperSlide virtualIndex={index} key={image.distance}>
							<SlideWrapper>
								<Image src={`/assets/images/${image.filename}.jpg`} />
							</SlideWrapper>
						</SwiperSlide>
					);
				})}
			</StyledSwiper>

			<StyledOverlay>
				<MapBox style={{display: sidebarVisible ? 'flex' : 'none'}}>
					<RouteMap visible={showMap} distance={currentDistance} />
				</MapBox>
				<Button style={{ pointerEvents: 'all', }} onClick={toggleMap}>
					{showMap ? <CloseIcon /> : <MapIcon />}
				</Button>
			</StyledOverlay>

			<GlobalButtonContainer>
				<FullscreenToggle exitLabel={t('exit_fullscreen')} enterLabel={t('enter_fullscreen')}/>
			</GlobalButtonContainer>
			
			<DistanceBox>{t('from_tallinn', { distance: currentDistance / 1000 })}</DistanceBox>
		</>
	);
}

export default App;
