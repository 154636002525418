import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ExtendedFeatureCollection, geoMercator, geoPath } from 'd3-geo';
import along from '@turf/along';
import '@erm-tasku/components/lib/bundle.css';
import { useAppContext } from '../contexts/AppContext';
import { PositionMarker } from './PositionMarker';
import { LandmarkMarker } from './LandmarkMarker';
import { useFetchJson } from '../hooks/useFetchJson';
import { pointer } from 'd3-selection';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import useResizeObserver from '@react-hook/resize-observer';
import { useTranslation } from 'react-i18next';

interface RouteMapProps {
	distance: number;
	visible?: boolean;
}

const MapSvg = styled.svg`
	width: 100%;
	height: 100%;
	overflow: visible;
`;

const MapContainer = styled.div`
	cursor: default;
	position: relative;
	margin: 30px 0;
	user-select: none;
	flex: 1;
	box-sizing: border-box;
	max-height: 100%;
	max-width: 100%;
`;

export const RouteMap: React.FC<RouteMapProps> = ({ distance, visible }) => {
	const {t} = useTranslation();
	const balticway = useFetchJson<ExtendedFeatureCollection>('/assets/thebalticway.geojson', {
		features: [],
		type: 'FeatureCollection',
	});
	const balticStates = useFetchJson<ExtendedFeatureCollection>('/assets/map_1.geojson', {
		features: [],
		type: 'FeatureCollection',
	});

	const containerRef = useRef<HTMLDivElement>(null);
	const [componentSize, setComponentSize] = useState({ width: 0, height: 0 });
	useResizeObserver(containerRef, (entry) => {
		const { width, height } = entry.contentRect;
		setComponentSize({ width, height });
	});
	const { markers, selectDistance } = useAppContext();
	const myProjection = useMemo(() => geoMercator(), []);
	const lineString = useMemo(() => balticway.features[0], [balticway]);
	let pointCoords: [number, number] = [24.73686622482296, 59.43510022800458];

	if (lineString) {
		myProjection.fitExtent(
			[
				[20, 20],
				[componentSize.width - 20, componentSize.height - 20],
			],
			balticway
		);

		const line = along(lineString as any, distance / 1000);
		pointCoords = (line.geometry.coordinates as [number, number]) || [0, 0];
	}

	function noop(content: any) {}

	const landmarks = useMemo(() => {
		noop(JSON.stringify({ componentSize, lineString })); // Stupid hack to bypass lint errors as we need to trigger this memo on window resize. Fix: use state instead of memo

		return markers
			.filter((marker) => marker.enabled === undefined || marker.enabled)
			.map((marker) => {
				const pos = myProjection(marker.lngLat);
				return (
					<LandmarkMarker key={marker.id} position={pos || [0, 0]} scale={0.5}>
						{t(marker.labelKey)}
					</LandmarkMarker>
				);
			});
	}, [componentSize, lineString, markers, myProjection, t]);

	const pos = myProjection(pointCoords);
	const geoDraw = useMemo(() => geoPath(myProjection), [myProjection]);

	const handleMapClick = (ev: React.MouseEvent) => {
		if (!lineString) return;

		const clickCoordinates = myProjection?.invert?.(pointer(ev));
		if (clickCoordinates) {
			const nearestPoint = nearestPointOnLine(lineString as any, clickCoordinates);
			const dist = (nearestPoint?.properties?.location || 0) * 1000;
			selectDistance(dist);
		}
	};

	const balticLand = useMemo(() => {
		noop(componentSize);
		return geoDraw(balticStates);
	}, [geoDraw, balticStates, componentSize]);

	return (
		<MapContainer ref={containerRef} onClick={handleMapClick}>
			<MapSvg style={{ width: componentSize.width, height: componentSize.height }}>
				<path d={balticLand || ''} fill={'#113955'} />
				<path d={geoDraw(balticway) || ''} stroke={`var(--green)`} fill={'none'} />
			</MapSvg>
			{landmarks}
			<PositionMarker position={pos || [0, 0]} />
		</MapContainer>
	);
};
