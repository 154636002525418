import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
	width: 28px;
	height: 28px;
	position: absolute;
	transform: translate(-50%, -50%);
	
	fill: none;
	stroke: white;
`;

interface MapMarkerProps {
	position: [number, number];
}

export const PositionMarker: React.FC<MapMarkerProps> = ({ position: [left, top] }) => {
	return (
		<Svg style={{ left, top }} viewBox={'-60 -60 120 120'}>
			<polygon vectorEffect="non-scaling-stroke" points="30,-52 60,0 30,52 -30,52 -60,0 -30,-52" />
			<line vectorEffect="non-scaling-stroke" x1="-30" y1="-52" x2="30" y2="52" />
			<line vectorEffect="non-scaling-stroke" x1="30" y1="-52" x2="-30" y2="52" />
			<line vectorEffect="non-scaling-stroke" x1="-60" y1="0" x2="60" y2="0" />
		</Svg>
	);
};

