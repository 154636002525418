import {createGlobalStyle} from "styled-components";
import {globalStyles} from '@erm-tasku/styles';

export const GlobalStyles = createGlobalStyle`
	${globalStyles}
	
	html, body {
		background: #000000;
	}
`;
